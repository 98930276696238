<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('images.edit') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar/> 
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
              <b-form @submit="onSubmit" autocomplete="off">
                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.title')" label-for="title">
                  <!-- Naslov je dosta specifičan. Ako je u pitanju latinično pismo na srpskom, onda se pored naslova -->
                  <!-- trebalo da se pojavi ikonica za LAT tag. -->
                  <template v-if="$root.lang.IANA === 'sr-Latn' || $root.lang.IANA === 'sr-Cyrl'">
                    <b-row>
                      <b-col md="11">
                        <b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('general.enterTitle')" autocomplete="new-password" trim></b-form-input>
                        <b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
                      </b-col>
                      <b-col md="1" class="col-form-label">
                        <span id="latIcon" @click="insertLat('title')">LAT</span>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-else>
                    <b-row>
                      <b-col md="12">
                        <b-form-input	id="title" :state="titleState" v-model="media.title" :placeholder="$t('videos.enter-title')" autocomplete="new-password"></b-form-input>
                        <b-form-invalid-feedback>{{	$t("general.enter-valid-title")	}}</b-form-invalid-feedback>
                      </b-col>
                    </b-row>
                  </template>
                </b-form-group>
              
                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('images.preview-image')" label-for="lowRes">
                  <b-img :src="media.posterUrl"  fluid-grow alt="." style="border-radius: 8px" />
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.description')" label-for="description">
                  <editor id="description" :init="tinyInitEditImage" v-model="media.description"></editor>
	                <b-form-invalid-feedback :state="descriptionState">{{ $t("general.enterDescription") }}</b-form-invalid-feedback>
                  <small v-if="$root.lang.IANA === 'sr-Latn'">Napomena: ukoliko unosite reči na stranim jezicima (latiničnim) označite ih i kliknite ikonicu LAT, kako bi se ispravno prikazale na ćiriličnoj verziji.</small>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.titleEn')" label-for="titleEn">
                  <b-form-input id="titleEn" v-model="media.titleEn" :placeholder="$t('images.enter-title-english')" autocomplete="new-password" trim></b-form-input>
				          <b-form-invalid-feedback :state="titleEnState">{{ $t("general.enterTitleEnError") }}</b-form-invalid-feedback>                  
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.descriptionEn')" label-for="description">
                  <editor id="descriptionEn" :init="tinyInitEditImage" v-model="media.descriptionEn"></editor>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.author')" label-for="author">
                  <b-form-input id="author" :state="authorState" v-model="media.author" :placeholder="$t('general.enter-author')" trim></b-form-input>
                  <b-form-invalid-feedback>{{ $t("general.enter-valid-author") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('videos.year-of-production')" label-for="year">
                  <b-form-input :state="yearState" id="year" v-model="media.year" :placeholder="$t('videos.enter-year-of-production')" trim></b-form-input>
                  <b-form-invalid-feedback>{{ $t("general.enter-valid-year") }}</b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('general.created')">
                  {{ this.dateTime(media.createdAt, 'long') }}
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('general.createdBy')">
                  <b-avatar :src="mediaUser.logo" class="mr-3" />{{ mediaUser.enterpriseName }} ({{ mediaUser.contactPerson }})
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('general.categories')">
                  <TagInput id="categories" :model="selectedCats" :state="categoriesState"  :options="avaiableCategoryList" :type="$t('general.category')" @input="selectedCats=$event" :error="$t('general.please-select-categories')"/>
                </b-form-group>

                <b-form-group v-if="admin.role.name=='SuperAdministrator'" label-cols-sm="12" label-cols-md="4" :label="$t('general.approve')">
                  <b-form-checkbox v-model="appruve" switch></b-form-checkbox>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('general.published')">
                  <b-form-checkbox v-model="media.published" switch></b-form-checkbox>
                </b-form-group>

                <b-form-group label-cols-sm="12" label-cols-md="4">
                  <b-row>
                    <b-col class="d-flex justify-content-between">

                      <template v-if="!media.deleted">
                        <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                            <b-button :disable="saveSpinner" type="submit" variant="primary">{{ $t('general.save-changes')}}</b-button>
                        </b-overlay>
                          <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                              <b-button :disable="saveSpinner" type="button" @click="deleteImage" variant="outline-danger">{{ $t('images.delete')}}</b-button>
                          </b-overlay>
                      </template>
                      <template v-else>
                        <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                            <b-button :disable="saveSpinner" type="button" @click="restoreImage" variant="secondary">{{ $t('images.restore-image')}}</b-button>
                        </b-overlay>
                        
                        <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5">
                            <b-button :disable="saveSpinner" type="button" @click="deleteImageForever" variant="outline-danger">{{ $t('images.delete-images-forever')}}</b-button>
                        </b-overlay>
                      </template> 
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-form>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal za brisanje-->
    <b-overlay :show="busy" no-wrap @shown="confirmDialog" @hidden="onHidden">
			<template #overlay>
				<div class="row">
					<b-col cols="12" ref="dialog"	tabindex="-1"	role="dialog"	aria-modal="false"	aria-labelledby="form-confirm-label"	class="bg-white align-self-center"	style="min-width: 500px;border: 2px solid #9ADCDA; border-radius: 8px; text-align: center;">
						<div class="p-4 mt-4">
              <div class="mb-3"><strong class="mb-3" id="form-confirm-label">{{$t("general.are-you-sure") }}</strong></div>
							<div class="mb-5 text-left">
                {{ $t("images.delete-images-forever-question") }}
							</div>
							<div class="mb-4">
								<b-button	variant="outline-primary"	class="mr-3"	@click="onCancel">{{ $t("general.no") }}</b-button>
								<b-button variant="outline-danger" @click="onOK">{{$t("general.yes")}}</b-button>
							</div>
						</div>
					</b-col>
				</div>
			</template>
		</b-overlay>
        
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import editor from '@tinymce/tinymce-vue'
import TagInput from "@/components/admin/_utils_/TagInput.vue"
import { BImg, BAvatar, BFormCheckbox } from 'bootstrap-vue'
export default {
  name: "EditImage",
  components: { NavBar, editor, BAvatar, BImg, TagInput, BFormCheckbox },
  data: function() {
    return {
      admin: this.$root.user,
      media: {},
      mediaUser: {},
      appruve: false,
      tinyInitEditImage: this.tinyInit(),
      showOverlay: true,
      selectedCats: [],
      avaiableCategoryList: [],
      saveSpinner: false,
			/* State varijable */
			titleState: null,
			mediaFileState: null,
			descriptionState: null,
			titleEnState: null,
			descriptionEnState: null,
			authorState: null,
			yearState: null,
			categoriesState: null,
			acceptedTermsState: null,
      busy: false,
      restoreSpinner: false
    }
  },
  created: async function() {
    this.displayTags();
    // Get Media
    await this.$http.get('/cms/api/media/get/' +  this.$route.params.imageId + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.media = r.data;
      // console.log(r.data);
      this.appruve = !this.media.pending;
      this.mediaUser = r.data.createdBy;
      this.selectedCats = this.extractTags(r.data.categories);
      }).catch(e => {
        this.handleError(e);
      })
      this.showOverlay = false;
  },
  computed: {
    _lang: function() {
      return this.$root.lang
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.media.categories = this.findRealTags(this.selectedCats);
      this.media.pending = !this.appruve;
      console.log("Vrednost iz elementa: " + document.getElementById('title').value);
      console.log("Vrednost iz media objekta: " + this.media.title);
      if (this.validateForm()) {
        this.$http.post("/cms/api/media/edit", this.media, {headers: {Authorization: this.getAuthData().accessToken}}).then(r => {
          this.saveSpinner = false;
          this.$router.go(-1);
        }).catch(e => {
          this.saveSpinner = false;
          this.handleError(e);
        })
      }
      this.saveSpinner = false;
    },

    findRealTags(tagList) {
      return this.categoryTagObjectsList.filter(function (item) {
              for (var i = 0; i < item.names.length; i++) {
                if (tagList.includes(item.names[i].name)) {
                  return item;
                }
              }
            })
    },

    extractTags(categoryList) {
      var adminLang = this.$root.lang.shortName;
      var myCategoriesSetring = [];
      categoryList.filter(function(item) {
              // console.log(item);
              for (var i = 0; i < item.names.length; i++) {
                if (item.names[i].language.shortName === adminLang) {
                  myCategoriesSetring.push(item.names[i].name);
                }
              }
            });
      return myCategoriesSetring;
    },

    validateForm: function() {
		  if (typeof this.media.title === "undefined" || this.media.title.length === 0) {
				this.titleState = false;
				document.getElementById("title").scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.titleState = null;
			}
      
			if (typeof this.media.description === "undefined" || this.media.description.length === 0) {
				this.descriptionState = false;
				document.getElementById("description").nextSibling.classList.add("is-invalid");
				document.getElementById("description").parentNode.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				document.getElementById("description").nextSibling.classList.remove("is-invalid");
				this.descriptionState = null;
			}
    
			if (typeof this.media.titleEn === "undefined" || this.media.titleEn.length === 0) {
				this.titleEnState = false;
				document.getElementById("titleEn").scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.titleEnState = null;
			}

			if (typeof this.media.descriptionEn === "undefined" || this.media.descriptionEn.length === 0) {
				this.descriptionEnState = false;
				document.getElementById("descriptionEn").nextSibling.classList.add("is-invalid");
				document.getElementById("descriptionEn").parentNode.scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				document.getElementById("descriptionEn").nextSibling.classList.remove("is-invalid");
				this.descriptionEnState = null;
			}

			if (typeof this.media.author === "undefined" || this.media.author.length === 0) {
				this.authorState = false;
				document.getElementById("author").scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				this.authorState = null;
			}

			if (typeof this.media.year === "undefined") {
				this.yearState = false;
				document.getElementById("year").scrollIntoView({ block: "center", behavior: "smooth" });
				return false;
			} else {
				const year = this.media.year.toString().replace(/[^0-9]+/g, "");
				if (year === "" || parseInt(year) === "NaN") {
					this.yearState = false;
					document.getElementById("year").scrollIntoView({ block: "center", behavior: "smooth" });
					return false;
				}
				this.media.year = year;
				this.yearState = null;
			}

			if (this.selectedCats.length === 0) {
				document.getElementById("categories").scrollIntoView({ block: "center", behavior: "smooth" });
				this.categoriesState = false;
				return false;
			} else {
				this.categoriesState = null;
			}

			if (this.acceptedTerms === "not_accepted") {
				document.getElementById("acceptedTerms").scrollIntoView({ block: "center", behavior: "smooth" });
				this.acceptedTermsState = false;
				return false;
			} else {
				this.acceptedTermsState = null;
			}
			return true;
    },

    async displayTags() {
      // Get all tags
      await this.$http.get("/cms/api/tag/all-published/" + this.$root.lang.shortName, { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
        this.categoryTagObjectsList = r.data
        if (r.data.length > 0) {
          var myOptions = [];
          for (var i = 0; i < r.data.length; i++) {
            var tag = r.data[i];
            if (tag.names.length > 0) {
              for (var j = 0; j < tag.names.length; j++) {
                var names = tag.names[j];
                if (names.language.shortName === this.$root.lang.shortName) {
                  myOptions.push(names.name);
                }
              }
            }
          }
          this.avaiableCategoryList = myOptions;
        }
      }).catch(e => { this.handleError(e); });
      const myCategories = this.findRealTags(this.selectedCats);
      this.selectedCats = this.extractTags(myCategories);      
    },

    deleteImage() {
      this.$http.post('/cms/api/media/delete/' +  this.media.id + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {        
        if (r.data === "OK") {
          this.$router.go(-1);
        }
      }).catch(e => {
        this.handleError(e);
      })
    },

    deleteImageForever() {
      this.busy = true;
    },

    restoreImage() {
      this.restoreSpinner = true;
      this.$http.post('/cms/api/media/restore-media/' +  this.media.id + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
         if (r.data === "OK") {
          this.restoreSpinner = false;
          this.$router.go(-1);
         }
      }).catch(e => {
        this.handleError(e);
      }) 
    }, 
    // Modal 
    confirmDialog: function() {
      this.$refs.dialog.focus();
    },
    onHidden: function() {
      // this.$refs.submit.focus();
    },
    onOK: function() {
      this.$http.post('/cms/api/media/delete-forever/' +  this.media.id + "/", { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
          if (r.data === "OK") {
          this.busy = false;
          this.$router.go(-1);
          }
      }).catch(e => {
        this.handleError(e);
      })      
    },
    onCancel: function() {
      this.busy = false;
    }
  },

  watch: {
    _lang : function() {
      // console.log("Promenjen jezik");
      this.displayTags();
    }
  }
}
</script>

<style></style>
